<template>
	<v-container>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="search.product"
              label="Название шаблона"
              placeholder="Название шаблона"
              outlined
              @change="debSearchPosts"
            >
            </v-text-field>
            <v-select
              v-model="search.channels"
              :items="channels"
              multiple
              item-value="id"
              item-text="name"
              label="Каналы"
              placeholder="Каналы"
              outlined
              @change="debSearchPosts"
            >
            </v-select>
            <v-menu
              ref="orderMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="dateMenu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="search.date"
                  label="Дата публикации поста"
                  :prepend-icon="mdiCalendar"
                  readonly
                  outlined
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search.date"
                no-title
                scrollable
                range
              >
              </v-date-picker>
            </v-menu>
            <v-switch
              v-model="postsType"
              :label="currentPostsType"
              false-value="simple"
              true-value="product"
              color="success"
              hide-details
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
		<v-row>
			<v-col v-if="postsType==='product'" cols="12">
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
				<v-simple-table v-else>
					<template v-slot:default>
						<thead>
							<tr>
								<th>ID</th>
								<th>Продукт</th>
								<th>Статус</th>
								<th>Время публикации</th>
                <th>Каналы</th>
                <th>Кто такой</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(post, i) in posts" :key="i" @click="showPost(post.pid)">
								<td>{{post.pid}}</td>
								<td v-if="post.product">{{post.product.title}}</td>
                <td><v-chip :color="getStatusColor(post.status)">{{post.status}}</v-chip></td>
								<td>{{post.planned_at}}</td>
                <td>{{getChannel(post.channels)}}</td>
                <td>{{getManager(post.manager_id)}}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
      <v-col v-else cols="12">
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
        <simple-posts></simple-posts>
      </v-col>
		</v-row>
	</v-container>
</template>

<script>
import _ from 'lodash'
import {mdiCalendar} from '@mdi/js'
import httpClient from '../../services/http.service'
import QueryString from 'qs'
import SimplePosts from '@/views/posts/SimplePosts'

export default {
  name: 'Posts',
  components:{
    SimplePosts,
  },
  data(){
    return {
      postsType: 'product', //product - товарка, simple - обычный пост
      mdiCalendar,
      dateMenu: false,
      posts: [],
      postsLoaded: false,
      loading: true,
      search: {product: '', channels: [1, 2, 3, 4], date: []}
    }
  },
  mounted() {
    this.searchPosts()
  },
  computed:{
    channels(){
      return JSON.parse(JSON.stringify(this.$store.state.channels))
    },
    managers(){
      return JSON.parse(JSON.stringify(this.$store.state.users))
    },
    currentPostsType(){
      if (this.postsType === 'simple'){
        return 'Обычные посты'
      }
      return 'Товарка'
    }
  },
  watch:{
    search:{
      deep: true,
      handler(){
        this.debSearchPosts()
      }
    }
  },
  methods:{
    debSearchPosts:_.debounce(function () {
      this.searchPosts()
    }, 500),
    searchPosts(){
      this.loading = true
      // httpClient.get(`posts?limit=25&date=${this.search.date}&product=${this.search.product}&channels=${this.search.channels}`).then(({data})=>{
      httpClient.get('posts', {params: {limit: 25, product: this.search.product, channels: this.search.channels, date: this.search.date}}).then(({data})=>{
        this.loading = false
        this.posts = data
      })
    },
    showPost(i){
      this.$router.push(`/posts/${i}`)
    },
    getChannel(channels){
      let channel = ''
      if (channels == null){
        return ''
      }
      channels.forEach((x, i)=>{
        if (i === channels.length-1){
          channel += `${this.channels.find(c=>c.id===x).name}`
        }else{
          channel += `${this.channels.find(c=>c.id===x).name}, `
        }
      })
      return channel
    },
    getManager(id){
      return this.managers.find(x=>x.id==id).name
    },
    getStatusColor(status){
      if (status === 'Опубликован'){
        return 'success'
      }
      if (status === 'Запланирован'){
        return 'info'
      }
      return 'warning'
    }
  }
}
</script>

<style scoped>

</style>
